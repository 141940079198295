import { useCallback, useState } from 'react';
import { useClient } from 'urql';

import { QueryUrlRedirectV2Args, UrlRedirect } from '__generated__/graphql';
import {
  URL_REDIRECT_QUERY,
  UrlRedirectQueryResult,
} from 'gql/queries/urlRedirect';
import { isContentOnline } from 'utils/isContentOnline';

type Link = {
  label: string;
  url: string;
};

const isRedirectActive = (redirect: UrlRedirect) => {
  const { availableFrom, availableTo } = redirect;
  const isAvailable = isContentOnline(
    {
      onlineFrom: availableFrom,
      onlineTo: availableTo,
    },
    {}
  );
  if (!isAvailable || !redirect.url) return false;
  return true;
};

export const useBreadcrumbUrlRedirects = (links: Link[]) => {
  const client = useClient();
  const [redirections, setRedirections] = useState<Link[] | undefined>(
    undefined
  );

  const fetchRedirections = useCallback(async () => {
    // Do not ask for first and last element
    const newArray = links.slice(1, -1);
    try {
      const results = await Promise.all(
        newArray.map(({ url }) => {
          return client
            .query<UrlRedirectQueryResult, QueryUrlRedirectV2Args>(
              URL_REDIRECT_QUERY,
              {
                path: url.startsWith('/') ? url.slice(1) : url,
              }
            )
            .toPromise();
        })
      );

      const updatedPaths: Link[] = results.map(({ data }, idx) =>
        data && isRedirectActive(data.urlRedirectV2)
          ? { label: links[idx + 1].label, url: data.urlRedirectV2.url! }
          : { ...links[idx + 1] }
      );

      if (updatedPaths.length > 0) {
        setRedirections([
          { ...links[0] },
          ...updatedPaths,
          { ...links[links.length - 1] },
        ]);
      }
    } catch (e) {}
  }, [client, links]);

  return { fetchRedirections, redirections };
};
