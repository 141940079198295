import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { tw } from 'twind/style';
import { Icon } from '@global-ecom/nitro-uds/elements';
import Head from 'next/head';

import { FilterOption, SortOption } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useFeature } from 'hooks/useFeature';
import { ResetSearchConditionsButton } from 'ui/elements/ResetSearchConditionsButton';
import { useResetConditionsButtonEnabled } from 'hooks/useResetConditionsButtonEnabled';
import { useIsCrawler } from 'hooks/useIsCrawler';
import { isServer } from 'utils/constants';

import type { FilterProps } from './ProductListFilter';

const ProductListFilterComponent = dynamic<FilterProps>(
  () => import('./ProductListFilter').then(mod => mod.ProductListFilter),
  { ssr: true }
);

const ProductListFilterCollapsibleComponent = dynamic<FilterProps>(
  () =>
    import('./ProductListFilterCollapsible').then(
      mod => mod.ProductListFilterCollapsible
    ),
  { ssr: false }
);
export interface ProductListProps {
  noResults: boolean;
  sortingOptions: SortOption[];
  defaultSortOption: string;
  filteringOptions: FilterOption[];
  totalCount: number;
  isFetching: boolean;
  search?: string;
  handleCompactMode?: (entry: boolean) => void;
}

export const ProductList: React.FC<ProductListProps> = props => {
  const [isCompactMode, setCompactMode] = useState(false);
  const filterCollapsableEnabled = useFeature('PRODUCT_FILTER_COLLAPSIBLE');
  const t = useTranslate();
  const isCrawler = useIsCrawler();
  const renderFilters = isServer && isCrawler ? true : !isServer;

  return (
    <>
      <Head>
        {props.search && <meta name="robots" content="noindex, follow" />}
      </Head>
      <section className="w-full flex flex-col justify-center font-display mx-auto max-w-screen-3xl">
        {!filterCollapsableEnabled && renderFilters && (
          <ProductListFilterComponent
            enabledFilterOptions={props.filteringOptions}
            sortOptions={props.sortingOptions}
            defaultSortOption={props.defaultSortOption}
          />
        )}
        {filterCollapsableEnabled && (
          <ProductListFilterCollapsibleComponent
            enabledFilterOptions={props.filteringOptions}
            sortOptions={props.sortingOptions}
            defaultSortOption={props.defaultSortOption}
          />
        )}
        <nav
          aria-label={t('layout')}
          className="flex w-full items-center justify-between py-5 lg:py-10 space-x-2"
        >
          <div>
            <span
              data-test-id="product-list-total-count"
              className="uppercase font-bold text-lg md:text-xl"
              aria-live="assertive"
              aria-atomic={true}
            >
              {!props.isFetching && (
                <>
                  {!props.search ? (
                    <span data-test-id="product-results">
                      {t('products', {
                        resultCount: props.totalCount,
                      })}
                    </span>
                  ) : (
                    <>
                      <span data-test-id="product-results">
                        {t('productResults', {
                          resultCount: props.totalCount,
                          searchTerm: props.search,
                        })}
                      </span>
                    </>
                  )}
                </>
              )}
            </span>
          </div>

          <div className="flex items-center">
            <button
              aria-pressed={isCompactMode}
              className="outline-none bg-transparent p-0 border-0"
              onClick={() => {
                setCompactMode(true);
                if (props.handleCompactMode) props.handleCompactMode(true);
              }}
              aria-label={t('compact')}
              type="button"
            >
              <div
                className={tw(
                  `m-2`,
                  isCompactMode && `border-1 border-puma-black`
                )}
              >
                <div className="block sm:hidden">
                  <Icon name="grid-col-1" size="2xl" />
                </div>
                <div className="hidden sm:block">
                  <Icon name="grid-col-2" size="2xl" />
                </div>
              </div>
            </button>
            <button
              aria-pressed={!isCompactMode}
              className="outline-none bg-transparent p-0 border-0"
              onClick={() => {
                setCompactMode(false);
                if (props.handleCompactMode) props.handleCompactMode(false);
              }}
              aria-label={t('relaxed')}
              type="button"
            >
              <div
                className={tw(
                  `m-2`,
                  !isCompactMode && `border-1 border-puma-black`
                )}
              >
                <div className="block sm:hidden">
                  <Icon name="grid-col-2" size="2xl" />
                </div>
                <div className="hidden sm:block lg:hidden">
                  <Icon name="grid-col-3" size="2xl" />
                </div>
                <div className="hidden lg:block">
                  <Icon name="grid-col-4" size="2xl" />
                </div>
              </div>
            </button>
          </div>
        </nav>

        <section aria-label={t('productResults')}>
          <h2 id="product-list-results-title" className="sr-only">
            {t('productResults')}
          </h2>
          {!props.isFetching && (
            <ProductListResults
              isCompactMode={isCompactMode}
              results={props.noResults ? null : props.children}
            />
          )}
        </section>
      </section>
    </>
  );
};

const ProductListResults: React.FC<{
  isCompactMode: boolean;
  results: null | React.ReactNode;
}> = ({ isCompactMode, results }) => {
  const t = useTranslate();
  const isResetConditionsButtonEnabled = useResetConditionsButtonEnabled();

  return !results ? (
    <div
      className={tw(
        'flex justify-center flex-col items-center',
        isResetConditionsButtonEnabled ? 'py-28' : 'py-48'
      )}
    >
      <h2
        id="no-products-found"
        className={tw(
          'text-3xl font-bold text-center',
          isResetConditionsButtonEnabled && 'mb-10'
        )}
      >
        {t('noProductsFound')}
      </h2>
      {isResetConditionsButtonEnabled && <ResetSearchConditionsButton />}
    </div>
  ) : (
    <ul
      className={tw(
        `grid gap-x-4 gap-y-8 transition-opacity duration-700`,
        `grid-cols-${isCompactMode ? '1' : '2'}`,
        `sm:grid-cols-${isCompactMode ? '2' : '3'}`,
        `lg:grid-cols-${isCompactMode ? '2' : '4'}`
      )}
      id="product-list-items"
      data-test-id="product-list-items"
    >
      {results}
    </ul>
  );
};
